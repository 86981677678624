<template>
    <Layout id="layouttttttttttt">
        <div class="row mt-2">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h1><i class="mdi mdi-credit-card-plus" style="transform: scale(1.7);"></i> Manage your sales</h1>

                        <label style="font-weight: 350;"><i class="mdi mdi-information"></i> This page allows you to add sales history via Excel or by filling a
                            form. </label>
                        <div v-if="importInvoice" class="accueil">
                            <div style="display: flex;justify-content: space-between;">
                                <button @click="importInvoice = false" type="button"
                                    class="btn btn-outline btn-info mb-3"><span class="btn-label"><i
                                            class="mdi mdi-arrow-left-bold-box-outline" style="transform: scale(1.7);"></i>
                                    </span>Go back </button>
                                <button @click="generateExcelFile()" type="button"
                                    class="btn btn-outline btn-info mb-3"><span class="btn-label"><i
                                            class="mdi mdi-arrow-down-bold-box-outline" style="transform: scale(1.7);"></i>
                                    </span>Get template </button>
                            </div>
                            <div class="form-container mb-2">

                                <h2 style="margin-bottom: 20px">Add invoice</h2>
                                <span> </span>

                                <div>
                                    <button class="file-upload-button" @click="handleFileUpload">
                                        Import
                                    </button>
                                </div>
                            </div>


                            <div v-if="data.length" class="table-responsive mt-5">
                                <table class="table table-striped mb-0">
                                    <thead>
                                        <tr>
                                            <th><input type="checkbox" v-model="toggleValue" @change="toggleList()" /></th>
                                            <th v-for="label in dataLabels">{{ label }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(entry, index) in data" :key="`tabledata_${index}`"
                                            @click="toggleCheckbox(index)">
                                            <th scope="row"><input type="checkbox" v-model="selectedIndexes[index]" /></th>
                                            <td v-for="(cell, indexx) in entry" :key="`celldata_${indexx}_${index}`"> {{
                                                cell }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div class="mt-5 mb-5 text-center">
                                <button type="submit" v-if="fileUploaded" @click="submitHandle"
                                    class="btn w-25 btn-outline-primary btn-bordered-primary">Add</button>
                            </div>

                        </div>
                        <div v-else-if="addingInvoice">
                            <button @click="addingInvoice = false;" type="button"
                                class="btn btn-outline btn-info mb-3"><span class="btn-label"><i
                                        class="mdi mdi-arrow-left-bold-box-outline" style="transform: scale(1.7);"></i>
                                </span>Go back </button>
                            <form @submit="preventsubmit">
                                <div class="formulaire">
                                    <div style="margin-left: auto; margin-right: auto; width: 50%;">
                                        <b-form-group label="Invoice Date" label-for="invoiceDate">
                                            <b-form-input v-model="invoiceDate" type="date" id="invoiceDate">

                                            </b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Due Date" label-for="dueDate">
                                            <b-form-input v-model="dueDate" type="date" id="dueDate">

                                            </b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Invoice Number" label-for="InvoiceNum">
                                            <b-form-input v-model="InvoiceNum" type="text" id="InvoiceNum"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Customer Name" label-for="CustName">
                                            <multiselect id="CustName" v-model="CustName" :options="CustNames">
                                            </multiselect>
                                        </b-form-group>
                                        <b-form-group label="Invoice Status" label-for="InvoiceStatus">
                                            <multiselect id="InvoiceStatus" v-model="InvoiceStatus"
                                                :options="InvoiceStatuses"></multiselect>
                                        </b-form-group>

                                        <b-form-group label="Total" label-for="total">
                                            <b-form-input v-model="total" type="text" id="total"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Currency" label-for="Currency">
                                            <multiselect id="Currency" v-model="currency" :options="currencies">
                                            </multiselect>
                                        </b-form-group>
                                        <b-form-group label="Balance" label-for="Balance">
                                            <b-form-input v-model="Balance" type="text" id="Balance"></b-form-input>
                                        </b-form-group>
                                        <div class="mt-5 mb-5 text-center">
                                            <div v-if="isLoading" class="mb-2" style="display: flex;
                                                justify-content: center;
                                                align-items: center;">
                                                <div class="loader"></div>
                                            </div>

                                            <button type="submit" class="btn w-25 btn-outline-primary btn-bordered-primary"
                                                @click="ajouterFacture()" :disabled="isLoading">Add</button>

                                        </div>
                                    </div>
                                </div>


                            </form>
                        </div>

                        <div v-else>
                            <p class="text-muted font-13 mb-4"></p>
                            <div class="row mb-md-4">
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_length" class="dataTables_length">
                                        <label class="d-inline-flex align-items-center">
                                            Show&nbsp;
                                            <b-form-select v-model="perPage" size="sm"
                                                :options="pageOptions"></b-form-select>&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <label class="d-inline-flex align-items-center">Add new invoices</label>
                                    <button type="button" class="btn btn-primary ml-2" @click="importInvoice = true"><i
                                            class="mdi mdi-microsoft-excel" style="transform: scale(1.7);"></i></button>
                                    <button type="button" class="btn btn-primary ml-2" @click="addingInvoice = true"><i
                                            class="mdi mdi-format-align-left" style="transform: scale(1.7);"></i></button>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                        <label class="d-inline-flex align-items-center">
                                            Search:
                                            <b-form-input v-model="filter" type="search" placeholder="Search..."
                                                class="form-control form-control-sm ml-2"></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive table-striped mb-0">
                                <b-table style="padding: .5rem;" :items="listeFacture" :fields="fields" responsive="sm"
                                    :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                    @filtered="onFiltered"></b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <b-pagination v-model="currentPage" :total-rows="rows"
                                                :per-page="perPage"></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import { read, utils, write } from 'xlsx';
import Layout from "./layouts/main";
import axios from "axios"
import swal from "sweetalert"
import Multiselect from 'vue-multiselect'
import emailjs from 'emailjs-com';

export default {
    data() {
        return {
            invoice: {},
            invoices: [],
            CompanyName: localStorage.getItem('societe').slice(1, -1),
            Balance: "",
            invoiceDate: "",
            dueDate: "",
            InvoiceNum: "",
            CustName: "",
            InvoiceStatus: "",
            total: "",
            currency: "",
            currencies: ['TND','USD', 'EUR', 'GBP'],
            InvoiceStatuses: ['Pending', 'Paid', 'Overdue', 'Refunded', 'Partially Refunded', 'Partially Paid', 'Cancelled'],
            InvoiceStatus: '',
            toggleValue: true,
            dataLabels: [],
            selectedIndexes: [],
            data: [],
            listeFacture: [],
            importInvoice: false,
            addingInvoice: false,
            fileUploaded: false,
            isLoading: false,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "",
            sortDesc: false,
            fields: [
                {
                    key: "Invoice Date",
                    sortable: true,
                },
                {
                    key: "Due Date",
                    sortable: true,
                },
                {
                    key: "Invoice Number",
                    sortable: true,
                },
                {
                    key: "Customer Name",
                    sortable: true,
                },
                {
                    key: "Invoice Status",
                    sortable: true,
                },
                {
                    key: "Total",
                    sortable: true,
                },
                {
                    key: "Currency",
                    sortable: true,
                },
                {
                    key: "Balance",
                    sortable: true,
                }
            ],
        };
    },
    components: {
        Layout,
        Multiselect,
    },
    async mounted() {
        axios.get(`https://finex.4help.tn/api/v1/getClients/${localStorage.getItem('societe').slice(1, -1)}`).then((res) => {
            this.CustNames = res.data.map((client) => client["Client Name"]);
        })
        await this.getFactureVente()
    },
    computed: {
        rows() {
            return this.listeFacture.length;
        },
    },
    methods: {
        preventsubmit(e) {
            e.preventDefault();
        },
        async sendEmail() {
            this.isLoading = true;
            console.log("sending email")
            const serviceID = 'service_bfyox0h'
            const templateID = 'template_62ozeot'
            const userID = '6sLq6KlrU8OK6Szrm'

            const templateParams = {
                subject: "Adding new invoice",
                CustName: this.CustName,
                message: "invoice number : " + this.InvoiceNum + " added successfully",
                to: "omar.fkii@outlook.com"

            }
            await emailjs.send(serviceID, templateID, templateParams, userID)
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                }).catch((error) => {
                    console.log('FAILED...', error);
                }).finally(() => {
                    this.isLoading = false
                })
        },
        ajouterFacture() {

            this.invoice["Societe"] = this.CompanyName
            this.invoice["Invoice Date"] = this.invoiceDate
            this.invoice["Due Date"] = this.dueDate
            this.invoice["Invoice Number"] = this.InvoiceNum
            this.invoice["Customer Name"] = this.CustName
            this.invoice["Invoice Status"] = this.InvoiceStatus
            this.invoice["Total"] = this.total
            this.invoice["Currency"] = this.currency
            this.invoice["Balance"] = this.Balance
            this.invoices[0] = this.invoice
            axios.post("https://finex.4help.tn/api/v1/addsaleinvoice", [[this.invoiceDate, this.dueDate, this.InvoiceNum, this.CustName, this.InvoiceStatus, this.currency, this.total, this.Balance, this.CompanyName,]]).then(async (res) => {
                if (res.status === 200) {
                    await this.sendEmail()


                    swal({
                        title: "Success",
                        text: `Successfully added ${this.invoice["Invoice Number"]}!`,
                        icon: "success",

                    });
                    this.getFactureVente();
                    this.currentPage = 1;
                    this.addingInvoice = false;
                    this.data = [];
                    this.fileUploaded = false;
                    //window.location.reload(true);

                } else {
                    swal({
                        title: "Error",
                        text: `Failed to add ${this.invoice["Invoice Number"]}!`,
                        icon: "error",
                    })
                }
            }).finally(() => {

            })

        },
        generateExcelFile() {
            // Create a new workbook
            const workbook = utils.book_new();

            // Create a data array containing the row data
            const data = [['Invoice Date', 'Due Date', 'Invoice Number', 'Customer Name', 'Invoice Status', 'Currency', 'Total', 'Balance']]; // Modify this array with your row data

            // Add the data array to a worksheet
            const worksheet = utils.aoa_to_sheet(data);

            // Add the worksheet to the workbook
            utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Generate Excel file binary data
            const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });

            // Convert Excel buffer to Blob
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

            // Create a download link element
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'template.xlsx';

            // Append the download link to the body and trigger the download
            document.body.appendChild(a);
            a.click();

            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        },
        toggleList() {
            this.selectedIndexes = Array.from({ length: this.data.length }, () => this.toggleValue)
        },
        toggleCheckbox(index) {
            this.$set(this.selectedIndexes, index, !this.selectedIndexes[index]);
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getFactureVente() {
            this.listeFacture = await axios.get(`https://finex.4help.tn/api/v1/getsaleinvoice/${localStorage.getItem('societe').slice(1, -1)}`).then(res => { return res.data })
            console.log(this.listeFacture)
        },
        async PostInvoice(data) {
            console.log("this is data in postInvoice : ",data)
            await axios.post("https://finex.4help.tn/api/v1/addsaleinvoice", data).then(async (res) => {
                if (res.status === 200) {
                    swal({
                        title: 'Success',
                        text: `Successfully added ${data.length} invoices!`,
                        icon: 'success',
                    }).then(() => {
                        this.getFactureVente()
                        
                    this.addingInvoice = false
                    
                    this.fileUploaded = false
                    });

                    
                } else {
                    swal({
                        title: 'Error',
                        text: `Server error.`,
                        icon: 'success',
                    });
                }
            }).then(() => {
                
            })
            this.showPopup = true;
            this.isPopupVisible = true;

        },

        initselectedIndexes(n) {
            this.selectedIndexes = Array.from({ length: n }, () => true)
            this.toggleValue = true
        },
        getSelectedData() {
            let selectedData = []
            this.selectedIndexes.map((value, index) => {
                if (value) selectedData.push(this.data[index])
            })
            return selectedData
        },
        submitHandle() {
            this.PostInvoice(this.getSelectedData())
            this.getFactureVente()
            // window.location.reload(true);
        },
        async handleFileUpload() {
            const input = document.createElement("input");
            input.type = "file";
            input.accept = ".xlsx, .xls";
            input.style.display = "none";
            input.addEventListener("change", async (event) => {
                const selectedFile = event.target.files[0];
                if (selectedFile) {
                    const file = selectedFile;
                    if (file) {
                        this.fileUploaded = true
                        const readFileAsync = (file) => {
                            return new Promise((resolve, reject) => {
                                const reader = new FileReader()
                                reader.onload = (e) => resolve(e.target.result)
                                reader.onerror = (e) => reject(e)
                                reader.readAsBinaryString(file)
                            });
                        };

                        try {
                            const data = await readFileAsync(file)
                            const workbook = read(data, { type: 'binary' })
                            const sheetName = workbook.SheetNames[0]
                            const sheet = workbook.Sheets[sheetName]
                            this.data = utils.sheet_to_json(sheet, { header: 1 })
                            //this.dataLabels = this.data[0]

                            this.dataLabels = this.data[0].map(column => column.trim())
                            this.data[0].push('Societe');


                            const dataModel = ['Invoice Date', 'Due Date', 'Invoice Number', 'Customer Name', 'Invoice Status', 'Currency', 'Total', 'Balance']
                            if (JSON.stringify(this.dataLabels) !== JSON.stringify(dataModel)) {
                                swal({
                                    title: 'Error',
                                    text: `Fields missmatch, please use our template.`,
                                    icon: 'error',
                                })
                                this.data = []
                                return
                            }
                            this.data.shift()
                            this.data.map((row, index) => {
                                row[8] = localStorage.getItem('societe').slice(1, -1)
                            })
                            this.initselectedIndexes(this.data.length)
                        } catch (error) {
                            console.error(error)
                            // Handle errors here
                        }
                    }
                }
            });

            input.click();

        },
        processExcelData(jsonData) {
            // Process jsonData if needed
            // For example, you can filter unwanted rows, modify data, etc.
            return jsonData;
        }
    }
};
</script>
<style >
/* HTML: <div class="loader"></div> */
.loader {
    width: 80px;
    aspect-ratio: 2;
    --c: no-repeat linear-gradient(#046D8B 0 0);
    background: var(--c), var(--c), var(--c), var(--c), var(--c), var(--c), var(--c);
    animation:
        l4-1 1.5s infinite,
        l4-2 1.5s infinite;
}

@keyframes l4-1 {
    0% {
        background-size: 0 4px, 4px 0, 0 4px, 4px 0, 0 4px, 4px 0, 0 4px
    }

    7.14% {
        background-size: 25% 4px, 4px 0, 0 4px, 4px 0, 0 4px, 4px 0, 0 4px
    }

    14.29% {
        background-size: 25% 4px, 4px 50%, 0 4px, 4px 0, 0 4px, 4px 0, 0 4px
    }

    21.43% {
        background-size: 25% 4px, 4px 50%, 25% 4px, 4px 0, 0 4px, 4px 0, 0 4px
    }

    28.57% {
        background-size: 25% 4px, 4px 50%, 25% 4px, 4px 100%, 0 4px, 4px 0, 0 4px
    }

    35.71% {
        background-size: 25% 4px, 4px 50%, 25% 4px, 4px 100%, 25% 4px, 4px 0, 0 4px
    }

    42.86% {
        background-size: 25% 4px, 4px 50%, 25% 4px, 4px 100%, 25% 4px, 4px 50%, 0 4px
    }

    49%,
    51% {
        background-size: 25% 4px, 4px 50%, 25% 4px, 4px 100%, 25% 4px, 4px 50%, 25% 4px
    }

    57.14% {
        background-size: 0 4px, 4px 50%, 25% 4px, 4px 100%, 25% 4px, 4px 50%, 25% 4px
    }

    64.29% {
        background-size: 0 4px, 4px 0, 25% 4px, 4px 100%, 25% 4px, 4px 50%, 25% 4px
    }

    71.43% {
        background-size: 0 4px, 4px 0, 0 4px, 4px 100%, 25% 4px, 4px 50%, 25% 4px
    }

    78.57% {
        background-size: 0 4px, 4px 0, 0 4px, 4px 0, 25% 4px, 4px 50%, 25% 4px
    }

    85.71% {
        background-size: 0 4px, 4px 0, 0 4px, 4px 0, 0 4px, 4px 50%, 25% 4px
    }

    92.86% {
        background-size: 0 4px, 4px 0, 0 4px, 4px 0, 0 4px, 4px 0, 25% 4px
    }

    100% {
        background-size: 0 4px, 4px 0, 0 4px, 4px 0, 0 4px, 4px 0, 0 4px
    }
}

@keyframes l4-2 {

    0%,
    49.9% {
        background-position: 0 50%, bottom 20px left 16px, 20px 0, 50% 0, 40px 100%, bottom 0 right 16px, 60px 50%
    }

    50%,
    100% {
        background-position: right 60px top 50%, 16px 0, right 40px top 0, 50% 100%, right 20px bottom 0, right 16px top 20px, 100% 50%
    }
}
</style>
<style scoped>
.form-container {
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.file-upload-button {
    background-color: #082748;
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

.file-upload-button:hover {
    background-color: #0056b3;
}

.accueil {
    text-align: center;
    padding: 4rem;
}

.accueil {
    text-align: center;
}

.card {
    min-height: 100%;
    margin-bottom: 0px !important;
}

h1 {
    font-size: 28px;
    margin-bottom: 20px;
}

label {
    font-weight: bold;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
}

th,
td {
    padding: 12px 15px;
    text-align: left;
}

th {
    background-color: #f2f2f2 !important;
    font-weight: bold;
    color: #333;
}


tr:nth-child(even) {
    background-color: #f2f2f2 !important;
}

tr:hover {
    background-color: #e0e0e0 !important;
    cursor: pointer;
    transition: background-color 0.3s;
}
</style>

<style>
.table.b-table>thead>tr>[aria-sort="none"],
.table.b-table>tfoot>tr>[aria-sort="none"] {
    background-position-x: left !important;
}
</style>